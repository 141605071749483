// Generated by Framer (13d9867)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {p4wWHsXeW: {hover: true, pressed: true}};

const serializationHash = "framer-N1q6o"

const variantClassNames = {p4wWHsXeW: "framer-v-n89vqq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "p4wWHsXeW", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-n89vqq", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"p4wWHsXeW"} ref={ref ?? ref1} style={{"--border-bottom-width": "0px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}} variants={{"p4wWHsXeW-hover": {"--border-bottom-width": "1px", "--border-color": "rgb(119, 85, 204)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px"}, "p4wWHsXeW-pressed": {backgroundColor: "rgb(0, 0, 0)"}}} {...addPropertyOverrides({"p4wWHsXeW-hover": {"data-border": true, "data-framer-name": undefined}, "p4wWHsXeW-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1kqr8eh-container"} layoutDependency={layoutDependency} layoutId={"b6hDPY5w5-container"}><Material color={"rgb(119, 85, 204)"} height={"100%"} iconSearch={"Home"} iconSelection={"ArrowForward"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"b6hDPY5w5"} layoutId={"b6hDPY5w5"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"p4wWHsXeW-pressed": {color: "rgb(255, 255, 255)"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-N1q6o.framer-1ejgrhh, .framer-N1q6o .framer-1ejgrhh { display: block; }", ".framer-N1q6o.framer-n89vqq { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 8px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-N1q6o .framer-1kqr8eh-container { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-N1q6o.framer-n89vqq { gap: 0px; } .framer-N1q6o.framer-n89vqq > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-N1q6o.framer-n89vqq > :first-child { margin-left: 0px; } .framer-N1q6o.framer-n89vqq > :last-child { margin-right: 0px; } }", ".framer-N1q6o[data-border=\"true\"]::after, .framer-N1q6o [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"OvR5PpMAa":{"layout":["auto","auto"]},"gCLnX8nB_":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLT5kFfHE6: React.ComponentType<Props> = withCSS(Component, css, "framer-N1q6o") as typeof Component;
export default FramerLT5kFfHE6;

FramerLT5kFfHE6.displayName = "Forward Arrow";

FramerLT5kFfHE6.defaultProps = {height: 32, width: 32};

addFonts(FramerLT5kFfHE6, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})